import React,{ useRef, useState, useEffect }  from 'react';
import List from '@mui/material/List';
import { useNavigate } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import CallMadeIcon from '@mui/icons-material/CallMade';


const SongList=()=>{
  const navigate=useNavigate();
  const BACKENDURL=process.env.REACT_APP_BACKEND_URL;

    const [songList,setSongList]=useState([]);

    useEffect(()=>{
        const fetchFiles= async()=>{
          const response = await axios.get(`${BACKENDURL}/files`);
          console.log("getting songs list");
          console.log(response.data.files);
          console.log(typeof(response.data.files));
          setSongList(response.data.files);
        }
        fetchFiles();
      },[]);



    return(
        <div>
        List of Songs
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {songList.map((value) => (
        <ListItem
          key={value.id}
          disableGutters
          secondaryAction={
            <IconButton aria-label="music" onClick={()=>navigate(`/song/${value.fileName}`)}>
              <CallMadeIcon />
            </IconButton>
          }
        >
          <ListItemText primary={value.fileName} />
        </ListItem>
      ))}
    </List>
      </div>
    )

};


export default SongList;