import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Upload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadStatus('Please select a file first');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    const BACKENDURL=process.env.REACT_APP_BACKEND_URL;

    try {
      const response = await axios.post(`${BACKENDURL}/file/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if(response.status===200){
        setUploadStatus(`Upload successful: ${response.data.message}`);
        window.location.reload();
      }
      
    } catch (error) {
      setUploadStatus(`Upload failed: ${error.response?.data?.message || error.message}`);
    }
  };

  useEffect(() => {
    // You can use useEffect for any side effects, e.g., logging or cleaning up
    return () => {
      // Cleanup function if needed
    };
  }, []);

  return (
    <div>
      <h2>Upload Music</h2>
      <input type="file" accept="audio/*" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  );
};

export default Upload;
