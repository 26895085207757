import './App.css';
import { useRef, useState, useEffect } from 'react';
import Upload from './components/uploadMusic/Upload';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import PlayerPage from './pages/Player/PlayerPage';
import SongList from './pages/SongList/SongList';
import NotFound from './pages/NotFound';

const App = () => {

  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path="/" element={<SongList/>} />
        <Route path="/song/:song_id" element={<PlayerPage/>} />
        <Route path="/uploadformusheer" element={<Upload/>} />
        <Route path="/*" element={<NotFound/>} />
    
        </Routes>
      </Router>

    </div>
  );
}

export default App;
