import React, { useRef, useState, useEffect } from "react";
import { songsdata } from "../../components/Player/audios";
import Player from "../../components/Player/Player";
import "./PlayerPage.css";
import { Route, useParams } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import axios from "axios";

const PlayerPage = () => {
  const [playingSongId, setPlayingSongId] = useState(null);
  const [isplaying, setisplaying] = useState(false);
  const [songList, setSongList] = useState([]);

  const [audio, setAudio] = useState(null);
  const [listCurrentSong, setListCurrentSong] = useState(null);
  const [isListSongPlaying, setIsListSongPlaying] = useState(false);
  const audioRef = useRef(null);

  const songs = [
    { id: 1, title: "Payphone" },
    { id: 2, title: "Girls Like You" },
    { id: 3, title: "Shotgun" },
  ];

  const handlePlayPauseClick = (song) => {
    console.log("getting song in handle play pause click", song);
    if (playingSongId === song.id) {
      toggleAudio();
    } else {
      const song_url = `${BACKENDURL}/file/${song.fileName}`;
      const newAudio = new Audio(song_url);
      audioRef.current = newAudio;
      setPlayingSongId(song.id);
      playAudio();
    }
  };
  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsListSongPlaying(true);
    }
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsListSongPlaying(false);
    }
  };

  const toggleAudio = () => {
    if (isListSongPlaying) {
      pauseAudio();
    } else {
      playAudio();
    }
  };

  useEffect(() => {
    const fetchFiles = async () => {
      const response = await axios.get(`${BACKENDURL}/files`);
      console.log("getting songs list");
      console.log(response.data.files);
      console.log(typeof response.data.files);
      setSongList(response.data.files);
    };
    fetchFiles();
  }, []);

  // const handlePlayPauseClick = (songId) => {
  //   setPlayingSongId((prevSongId) => (prevSongId === songId ? null : songId));
  // };

  let { song_id } = useParams();
  console.log("getting id", song_id);

  console.log("getting songs data", songsdata);

  const audioElem = useRef();

  useEffect(() => {
    if (isplaying) {
      audioElem.current.play();
    } else {
      audioElem.current.pause();
    }
  }, [isplaying]);

  const BACKENDURL = process.env.REACT_APP_BACKEND_URL;

  const currentSong = {
    title: song_id.split(".")[0],
    url: `${BACKENDURL}/file/${song_id}`,
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = currentSong.url;
    link.download = currentSong.fileName; // Ensure currentSong includes the fileName property
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadFromList = (song) => {
    const link = document.createElement("a");
    const song_url= `${BACKENDURL}/file/${song.fileName}`;
    link.href = song_url;
    link.download = song.fileName; // Ensure currentSong includes the fileName property
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const limitedSongList = songList.slice(11, 13);

  return (
    <div className="playerPageDiv">
      <div className="player">
        <audio src={currentSong.url} ref={audioElem} />
        <Player
          isplaying={isplaying}
          setisplaying={setisplaying}
          audioElem={audioElem}
          currentSong={currentSong}
        />
        <div style={{ marginTop: "2rem" }}>
        <div style={{ fontSize: '18px', color: '#555', marginTop: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', transition: 'box-shadow 0.3s' }} onMouseEnter={(e) => e.target.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)'} onMouseLeave={(e) => e.target.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.1)'}>
  {song_id}
</div>

          <button
            onClick={handleDownload}
            style={{
              backgroundColor: "rgb(246,147,32)",
              color: "#fff",
              padding: "10px 20px",
              marginTop:"1rem",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "bold",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s ease",
            }}
          >
            Download Song
          </button>
        </div>
      </div>
      <div className="songList">
        <h2>Songs you may like</h2>

        <ul className="list">
          {limitedSongList.map((song) => (
            <li key={song.id} className="list-item">
              <button
                onClick={() => handlePlayPauseClick(song)}
                className="play-pause-button"
              >
                {playingSongId === song.id && isListSongPlaying ? (
                  <PauseIcon />
                ) : (
                  <PlayArrowIcon />
                )}
              </button>
              {song.fileName.split('.')[0]}
              <button className="download-button" onClick={()=>{
                handleDownloadFromList(song)
              }}>
                <DownloadIcon />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PlayerPage;
