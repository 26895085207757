exports.songsdata = [
    {
        "title": "Drake - Forever",
        "url": "http://localhost:8001/file/1715945030725-music-intro-205584.mp3"
    },
    {
        "title": "Linking Park - In the end",
        "url": "http://localhost:8001/file/1715945030725-music-intro-205584.mp3"
    },
    {
        "title": "Travis Scott - Stop trina be God",
        "url": "http://localhost:8001/file/1715945030725-music-intro-205584.mp3"
    }
]